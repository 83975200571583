<template>
	<div data-route>
		<page-header
			heading="Articles"
			:action="getHeaderAction"
		/>
		<div data-element="main">
			<table-view
				:table="getTable"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Articles'
		},
		components: {
			PageHeader,
			TableView
		},
		data: () => ({
			articles: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Title'
					],
					rows: this.articles.map((article) => {
						return {
							route: `/admin/knowledgebase/articles/${article.id}`,
							columns: [
								article.title
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add article',
					route: '/admin/knowledgebase/articles/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/articles',
						text: 'Articles'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setArticles();
		},
		methods: {
			async setArticles () {
				const response = await api.admin.getAllKnowledgebaseArticles({
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems              = response.meta.totalItems;
				this.pagination.totalPages   = response.meta.numPages;
				this.pagination.currentPage  = response.meta.page;
				this.articles                = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			onSearch (value) {
				this.searchString = value;
				this.setArticles();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setArticles();
			}
		}
	};
</script>
